import React from "react"

import { ReactComponent as HomeIcon } from "images/icons/home-icon.svg"

import { BreadcrumbsContainer, HomeLink, BreadcrumbsLink, Arrow } from "./style"

const Breadcrumbs = ({ title, uri }) => {

  console.log(title)
  return (
    <BreadcrumbsContainer>
      <HomeLink to="/">
        <HomeIcon className="home-icon" />
        <Arrow />
      </HomeLink>

      <BreadcrumbsLink
        to={uri}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </BreadcrumbsContainer>
  )
}

export default Breadcrumbs
