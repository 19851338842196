import React from "react"
import { LocationModuleContainer } from "./style"
import AvenueImg from "UI/AvenueImg"
import { useStaticQuery, graphql } from "gatsby"

const LocationModule = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wp {
          siteOptions {
            siteSettings {
              address
              phoneNumber
              faxNumber
              mapImage {
                sourceUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
      }
    `
  )

  console.log(data)
  const siteSettings = data?.wp?.siteOptions?.siteSettings

  return (
    <LocationModuleContainer>
      <AvenueImg fluid={siteSettings.mapImage} />
      <p className="address">{siteSettings?.address}</p>
      <div className="number">
        <span>P</span>
        <a href={`tel:${siteSettings?.phoneNumber}`}>
          {siteSettings?.phoneNumber}
        </a>
      </div>
      <div className="number">
        <span>F</span>
        <a href={`tel:${siteSettings?.faxNumber}`}>{siteSettings?.faxNumber}</a>
      </div>
    </LocationModuleContainer>
  )
}

export default LocationModule
