import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "UI/MainColumn"
import { Col } from "UI/Grid"
import arrowRight from "components/UI/Dropdown/down-arrow.svg"

export const LeftCol = styled(Col)`
  @media ${device.desktop} {
    padding-right: 45px !important;
  }
`
export const InternalTemplateContainer = styled(MainColumn)`
  @media ${device.desktop} {
    margin-top: ${(props) => (props.variation === "related" ? "15px" : "0")};
  }
  .pdfPreview {
    padding: 10px;
    background: #d8d8d8;
    margin-top: 35px;

    @media ${device.tablet} {
      padding: 37px 76px;
    }
    @media ${device.desktop} {
      margin-top: 50px;
    }

    canvas {
      width: 100%;
    }
  }

  .box {
    display: flex;
    z-index: 2;
    height: 0;
    margin: 0 !important;
    padding: 0 !important;
    .is-2 {
      display: none !important;
    }

    .buttons {
      height: 60px;
      display: inline-flex;
      box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.25);
      margin: 0 auto;
      transform: translateY(-120px);
      z-index: 2;
      background-color: white !important;
      flex: inherit !important;
      width: auto !important;
    }

    button {
      height: 100%;
      width: 60px;
      /* background: red; */
      position: relative;
      background: none !important;

      &:hover {
        background: #eee !important;
      }

      &:disabled {
        /* background: #dddddd; */
        pointer-events: none;

        &:before {
          opacity: 0.3;
        }
      }

      &:before {
        content: "";
        background-image: url(${arrowRight});
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) rotate(-90deg);
        height: 6px;
        width: 10px;
        z-index: 1;
      }

      &:first-child {
        &:before {
          transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
      }
      span {
        display: none;
      }
    }

    span.is-inline-flex {
      /* background: blue; */
      height: 100%;
      display: flex;
      background: none !important;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      color: black;
      padding: 0 10px;
    }
  }
`

export const PageTitle = styled.h2`
  font-size: 24px;
  font-weight: normal;

  @media ${device.desktop} {
    font-size: 36px;
  }
`

export const PageDetails = styled.div`
  line-height: 110%;
  margin: 20px 0;

  @media ${device.desktop} {
    margin-bottom: 45px;
  }

  span {
    font-size: 12px;
    font-weight: 500;

    @media ${device.desktop} {
      font-size: 14px;
    }
  }

  span.name {
    color: #008873;
  }

  span.author {
    color: #008873;
    position: relative;
    margin-right: 16px;

    @media ${device.desktop} {
      margin-right: 20px;
    }

    :before {
      content: "";
      position: absolute;
      top: 3px;
      right: -11px;
      height: 4px;
      width: 4px;
      background: #c4cfd7;
      border-radius: 50%;

      @media ${device.desktop} {
        top: 4px;
        right: -13px;
      }
    }
  }
`

export const Footnotes = styled.div`
  font-size: 14px;
  margin: 10px 0;
  @media ${device.desktop} {
    font-size: 16px;
  }

  h3 {
    font-weight: 500;
    font-size: inherit;
    margin-bottom: 15px;

    @media ${device.desktop} {
      margin-bottom: 20px;
    }
  }
  ol {
    margin-left: 17px;

    li {
      margin-bottom: 17px;
    }
  }

  a.downloadLink {
    font-size: 15px;
    color: ${(props) => props.theme.green};
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 100%;
    margin-top: 30px;
    svg {
      height: 16px;
      width: 18px;
      margin-right: 8px;
    }

    @media ${device.desktop} {
      font-size: 18px;
      margin-top: 35px;

      svg {
        height: 18px;
        width: 22px;
        margin-right: 15px;
      }
    }

    :hover {
      color: #32bda3;
      g {
        stroke: #32bda3;
      }
    }
  }

  .avenueImg {
    margin: 30px 0;

    @media ${device.desktop} {
      margin: 50px 0 35px 0;
    }
  }
`

export const InternalRHSMenu = styled.h2`
  font-family: 500;
  font-size: 14px;
  color: #2e3a47;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.primaryColor};

  @media ${device.desktop} {
    padding-bottom: 21px;
    margin-top: 0;
  }
`

export const PDFWrap = styled.div`
  
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  
`
