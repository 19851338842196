import styled from "styled-components"
import { device } from "breakpoints"

export const LocationModuleContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e9eef2;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 0 20px 20px 20px;
  font-size: 14px;

  .avenueImg {
    margin: 0 -20px;
    border-radius: 10px 10px 0 0;

    > div {
      padding-bottom: 64.64% !important;
    }
  }

  p {
    margin-top: 26px;
    padding-bottom: 18px;
    border-bottom: 1px solid #e2e8ed;
    margin-bottom: 20px;
  }

  div.number {
    font-size: 14px;
    margin-top: 10px;

    span {
      font-weight: 500;
      color: #1b7e8d;
      margin-right: 13px;
    }

    a {
      :hover {
        color: ${(props) => props.theme.green};
      }
    }
  }
`
