import styled from "styled-components"
import { device } from "breakpoints"
import { Link } from "gatsby"
import { ReactComponent as ArrowIcon } from "images/icons/breadcrumb-arrow.svg"

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  border-bottom: 2px solid #4ea3b3;
  padding-bottom: 10px;
  flex-wrap: wrap;
  margin-bottom: 25px;
  padding-left: 33px;
  position: relative;
  @media ${device.desktop} {
    padding-bottom: 20px;
    padding-left: 38px;
    margin-top: 0;
    margin-bottom: 40px;
  }
`

export const BreadcrumbsLink = styled(Link)`
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  color: ${(props) => props.theme.green};
  display: inline-flex;
  align-items: center;
  margin-right: 10px;

  @media ${device.desktop} {
    font-size: 12px;
  }

  :hover {
    color: #32bda3;

    svg.home-icon {
      path {
        fill: #32bda3;
      }
    }
  }
`

export const HomeLink = styled(BreadcrumbsLink)`
  position: absolute;
  left: 0;
  top: 4px;

  @media ${device.desktop} {
    top: 3px;
  }

  svg.home-icon {
    width: 13px;
    height: 12px;
    margin-right: 5px;

    @media ${device.desktop} {
      width: 15px;
      height: 14px;
      margin-right: 7px;
    }
  }
`

export const Arrow = styled(ArrowIcon)`
  width: 7px;
  height: 10px;
  margin-right: 10px;
`
